@import "variables";
@import "media-queries";
@import "reset";
@import "css/footer";
@import "css/header";
@import "css/main-content";

body {
    background-color: $blue;
    color: white;
    -webkit-font-smoothing: antialiased;
}

.ih-header__title,
.ih-sub-head,
.ih-neighbor,
.ih-footer {
    margin-left: 19%;
    margin-right: 8%;
}

.ih__link {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.ih__copy {
    font-family: $ag;
    font-weight: $font-weight-reg;
    font-size: 3.3vw;
    letter-spacing: 0.7px;
    line-height: 235%;
}

/*************************************************************
 ** 320px Enhanced - Ideal for mobile                       **
 ** Inherits from 320px standard                            **
 ** Media query checks for CSS3 support. Has touch support. **
 *************************************************************/

@media #{$mq-smallest} {
}

/*******************************************************
 ** 480px - Ideal for iPhone Landscape, other mobiles **
 ** Inherits from 320px Standard, and 320px Enhanced  **
 ** Has touch support                                 **
 *******************************************************/

@media #{$mq-small} {
}

/*************************************************************
 ** 720px - Ideal for iPad Portrait                         **
 ** Inherits from 320px Standard, 320px Enhanced, and 480px **
 ** Has touch support.                                      **
 *************************************************************/

@media #{$mq-medium} {
    .ih-sub-head {
        margin: 0;
    }

    .ih-header__title {
        margin-left: 26.5%;
        margin-right: 7%;
    }

    .ih-sub-head__sub-title,
    .ih-sub-head__copy-wrap,
    .ih-neighbor {
        margin-left: 26.5%;
        margin-right: 5%;
    }

    .swiper-container,
    .ih-sub-head__credits-wrap,
    .ih-footer {
        margin-left: 26.5%;
    }

    .ih__copy {
        font-size: 1.6vw;
        line-height: 230%;
    }
}

/************************************************************
 ** 960px - Ideal for most browser screens, iPad Landscape **
 ** Inherits all the things.                               **
 ** Has touch support.                                     **
 ************************************************************/

@media #{$mq-large} {
    .ih-header__title {
        margin-right: 12%;
    }

    .ih__copy {
        font-size: 1.3vw;
    }
}

/**********************************************
 ** 1200px - Ideal for wider browser screens **
 ** Inherits all the things.                 **
 ** Has touch support.                       **
 **********************************************/

@media #{$mq-largest} {
}

/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {
}
