.ih-sub-head {
    margin-bottom: 8%;
}

.ih-sub-head__sub-title {
    font-family: $ag-ex;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-size: 5vw;
    letter-spacing: 2px;
    line-height: 208%;
    width: 100%;
}

.ih-sub-head__copy {
    margin-top: 2%;
}

.swiper-container {
    width: 100%;
    height: 100%;
    margin-top: 15%;
    z-index: 0;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
    background-image: url("../img/arrow.svg");
    background-size: 10px 18px;
    right: 0;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 1;
}

.swiper-button-next {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.swiper-button-prev {
    left: 0;
}

.ih-sub-head__credits {
    font-family: $ag-ex;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-size: 2.5vw;
    letter-spacing: 2px;
    line-height: 257%;
    margin-top: 3vw;
    width: 90%;
}

.ih-sub-head__credits--link {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.ih-main-content__watertower {
    width: 95%;
    display: block;
    margin: 0 auto;
}

.ih-neighbor__title {
    font-family: $ag;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    line-height: 170%;
    font-size: 7vw;
    letter-spacing: 1.7px;
    margin: 15% 0 10%;
}

.ih-neighbor__co-wrap {
    margin-bottom: 36%;
}

.ih-neighbor__image {
    width: 75%;
}

.ih-neighbor__sub-title {
    font-family: $ag-ex;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 4.5vw;
    line-height: 190%;
    margin: 6% 0 0 0;
    width: 85%;
}

.ih-neighbor__address {
    font-family: $ag-ex;
    font-weight: $font-weight-reg;
    text-transform: uppercase;
    font-size: 2.9vw;
    letter-spacing: 1.3px;
    line-height: 300%;
}

.ih-neighbor__address--line-two {
    margin-bottom: 5%;
}

.ih-neighbor__line {
    width: 12%;
    height: 2px;
    background-color: white;
}

.ih-neighbor__copy {
    margin: 6% 0 11% 0;
}

.ih-neighbor__link {
    font-family: $ag-ex;
    font-weight: $font-weight-reg;
    text-transform: uppercase;
    font-size: 3.5vw;
    letter-spacing: 3px;
    padding-bottom: 9px;
    background-image: linear-gradient(to right, #fff 15%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
}

/*************************************************************
 ** 320px Enhanced - Ideal for mobile                       **
 ** Inherits from 320px standard                            **
 ** Media query checks for CSS3 support. Has touch support. **
 *************************************************************/

@media #{$mq-smallest} {
}

/*******************************************************
 ** 480px - Ideal for iPhone Landscape, other mobiles **
 ** Inherits from 320px Standard, and 320px Enhanced  **
 ** Has touch support                                 **
 *******************************************************/

@media #{$mq-small} {
}

/*************************************************************
 ** 720px - Ideal for iPad Portrait                         **
 ** Inherits from 320px Standard, 320px Enhanced, and 480px **
 ** Has touch support.                                      **
 *************************************************************/

@media #{$mq-medium} {
    .ih-sub-head {
        position: relative;
    }

    .ih-sub-head__sub-title {
        font-size: 2.3vw;
        width: inherit;
    }

    .ih-sub-head__copy-wrap {
        margin: 0 0 6% 0;
    }

    .ih-sub-head__copy {
        margin-top: 2%;
    }

    .swiper-container {
        width: auto;
        margin-top: 5%;
    }

    .swiper-slide {
        width: 93%;
    }

    .swiper-button {
        margin-top: 6%;
        margin-right: 11%;
    }

    .swiper-button-next,
    .swiper-button-prev {
        position: inherit;
        width: 6%;
        display: inline-block;
        float: right;
    }

    .ih-sub-head__credits-wrap {
        position: absolute;
        bottom: 5px;
        display: inline-block;
        width: 53%;
    }

    .ih-sub-head__credits {
        font-size: 1.3vw;
        line-height: 260%;
        margin-top: 0;
        width: 100%;
    }

    .ih-sub-head__credits--link {
        margin-left: 0;
        z-index: 10;
    }

    .ih-main-content__watertower {
        width: 90%;
        margin-top: 10%;
    }

    .ih-neighbor__title {
        font-size: 6vw;
        line-height: 140%;
        letter-spacing: 3px;
        margin: 8% 0 4% 0;
    }

    .ih-neighbor__companies {
        width: auto;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .ih-neighbor__co-wrap {
        display: inline-block;
        width: 45%;
        vertical-align: top;
        margin-bottom: 8vw;
    }

    .ih-neighbor__image {
        width: 100%;
    }

    .ih-neighbor__sub-title {
        font-size: 2vw;
        letter-spacing: 1px;
        margin-top: 4%;
    }

    .ih-neighbor__address {
        font-size: 1vw;
        line-height: 300%;
    }

    .ih-neighbor__address--line-two {
        margin-bottom: 4%;
    }

    .ih-neighbor__line {
        width: 7%;
    }

    .ih-neighbor__copy {
        width: 85%;
        margin-top: 4%;
        margin-bottom: 2%;
    }

    .ih-neighbor__link {
        font-size: 1vw;
        padding-bottom: 10px;
    }
}

/************************************************************
 ** 960px - Ideal for most browser screens, iPad Landscape **
 ** Inherits all the things.                               **
 ** Has touch support.                                     **
 ************************************************************/

@media #{$mq-large} {
    .ih-sub-head__credits-wrap {
        margin-left: 22%;
        bottom: 12px;
    }

    .ih-sub-head__credits {
        font-size: 0.9vw;
        line-height: 260%;
        margin-top: 0;
        width: 100%;
    }

    .ih-sub-head__credits--link {
        margin-left: 0;
        z-index: 10;
    }

    .ih-sub-head__sub-title {
        font-size: 1.9vw;
    }

    .ih-neighbor__wrap {
        margin-bottom: 6%;
    }

    .ih-neighbor__title {
        font-size: 5.5vw;
    }

    .ih-neighbor__sub-title {
        font-size: 1.7vw;
    }

    .ih-neighbor__link {
        font-size: 0.8vw;
    }
}

/**********************************************
 ** 1200px - Ideal for wider browser screens **
 ** Inherits all the things.                 **
 ** Has touch support.                       **
 **********************************************/

@media #{$mq-largest} {
}

/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {
}
