.ih-footer__line-break {
    height: 2px;
    background-color: white;
    display: block;
    margin: 0 8% 0 19%;
}

.ih-footer {
    font-family: $ag-ex;
    font-weight: $font-weight-reg;
    text-transform: uppercase;
    margin-top: 13%;
    margin-bottom: 40%;
    font-size: 2.8vw;
}

.ih-footer__social {
    letter-spacing: 4px;
}

.ih-footer_link {
    line-height: 445%;
    padding-bottom: 6px;
    background-image: linear-gradient(to right, #fff 15%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
}

.ih-footer__contact {
    margin-top: 20%;
    margin-bottom: 26%;
    letter-spacing: 1.8px;
}

.ih-footer__number {
    margin-bottom: 10%;
}

.ih-footer__more {
    letter-spacing: 1.8px;
}

.ih-footer__copyright {
    margin-bottom: 11%;
}

/*************************************************************
 ** 320px Enhanced - Ideal for mobile                       **
 ** Inherits from 320px standard                            **
 ** Media query checks for CSS3 support. Has touch support. **
 *************************************************************/

@media #{$mq-smallest} {
}

/*******************************************************
 ** 480px - Ideal for iPhone Landscape, other mobiles **
 ** Inherits from 320px Standard, and 320px Enhanced  **
 ** Has touch support                                 **
 *******************************************************/

@media #{$mq-small} {
}

/*************************************************************
 ** 720px - Ideal for iPad Portrait                         **
 ** Inherits from 320px Standard, 320px Enhanced, and 480px **
 ** Has touch support.                                      **
 *************************************************************/

@media #{$mq-medium} {
    .ih-footer__line-break {
        margin: 0 5% 0 26.5%;
    }

    .ih-footer {
        margin-top: 7%;
        margin-bottom: 15%;
        font-size: 1vw;
    }

    .ih-footer__social {
        width: 18%;
        letter-spacing: 2px;
    }

    .ih-footer_link {
        padding-bottom: 10px;
    }

    .ih-footer__social,
    .ih-footer__contact,
    .ih-footer__more {
        display: inline-block;
        vertical-align: top;
    }

    .ih-footer__contact,
    .ih-footer__more {
        line-height: 440%;
        margin-top: 0;
    }

    .ih-footer__contact {
        width: 40%;
        margin-bottom: 0;
    }

    .ih-footer__number {
        margin-bottom: 0;
    }

    .ih-footer__copyright {
        margin-bottom: 0;
    }
}

/************************************************************
 ** 960px - Ideal for most browser screens, iPad Landscape **
 ** Inherits all the things.                               **
 ** Has touch support.                                     **
 ************************************************************/

@media #{$mq-large} {
    .ih-footer {
        font-size: 0.8vw;
    }

    .ih-footer_link,
    .ih-footer__contact,
    .ih-footer__more {
        line-height: 450%;
    }
}

/**********************************************
 ** 1200px - Ideal for wider browser screens **
 ** Inherits all the things.                 **
 ** Has touch support.                       **
 **********************************************/

@media #{$mq-largest} {
}

/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {
}
