.ih-header {
    position: relative;
    overflow-x: hidden;
}

.ih-header__logo {
    background-image: url("../img/logo_icon.svg");
    background-repeat: no-repeat;
    height: 14vw;
    width: 14vw;
    background-size: contain;
    margin: 5% 0 0 5%;
    position: fixed;
    z-index: 5;
}

.ih-header__video-wrap {
    width: 133%;
    margin-left: -10%;
    position: absolute;
    z-index: -1;
}

.ih-header__video-wrap::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}
.ih-header__video-wrap::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}
.ih-header__video-wrap::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}

.ih-header__title {
    font-family: $ag;
    font-weight: $font-weight-bold;
    font-size: 13vw;
    line-height: 138%;
    padding: 28% 0 11% 0;
    z-index: 5;
}

/*************************************************************
 ** 320px Enhanced - Ideal for mobile                       **
 ** Inherits from 320px standard                            **
 ** Media query checks for CSS3 support. Has touch support. **
 *************************************************************/

@media #{$mq-smallest} {
}

/*******************************************************
 ** 480px - Ideal for iPhone Landscape, other mobiles **
 ** Inherits from 320px Standard, and 320px Enhanced  **
 ** Has touch support                                 **
 *******************************************************/

@media #{$mq-small} {
}

/*************************************************************
 ** 720px - Ideal for iPad Portrait                         **
 ** Inherits from 320px Standard, 320px Enhanced, and 480px **
 ** Has touch support.                                      **
 *************************************************************/

@media #{$mq-medium} {
    .ih-header__logo {
        background-image: url("../img/industry-hill-logo.svg");
        transform: rotate(90deg);
        transform-origin: 0% 0%;
        display: inline-block;
        width: 38%;
        margin: 6% 0 0 21%;
    }

    .ih-header__video-wrap {
        width: 100%;
        margin-left: 0;
    }

    .ih-header__title {
        font-size: 11vw;
        line-height: 126%;
        letter-spacing: 2px;
        margin: 20% 0 6% 0;
        padding: 0;
        display: inline-block;
        float: right;
    }
}

/************************************************************
 ** 960px - Ideal for most browser screens, iPad Landscape **
 ** Inherits all the things.                               **
 ** Has touch support.                                     **
 ************************************************************/

@media #{$mq-large} {
}

/**********************************************
 ** 1200px - Ideal for wider browser screens **
 ** Inherits all the things.                 **
 ** Has touch support.                       **
 **********************************************/

@media #{$mq-largest} {
}

/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {
}
